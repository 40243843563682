define(['lodash',
    'santa-components',
    'previewExtensionsCore',
    'coreUtils'],
function (_, santaComponents, previewExtensionsCore, coreUtils) {
    'use strict';

    const compTypes = ['wysiwyg.viewer.components.tpapps.TPAWidget',
        'wysiwyg.viewer.components.tpapps.TPASection',
        'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        'wysiwyg.viewer.components.tpapps.TPAMultiSection'];
    const previewExtensionsRegistrar = previewExtensionsCore.registrar;

    const extension = {
        propTypes: {
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired
        },

        /* eslint-disable complexity */
        setOverlayState() {
            const underMobileAndNotSupported = this.isUnderMobileView() && this.isMobileReady && !this.isMobileReady();
            let overlay;
            if (underMobileAndNotSupported) {
                overlay = 'unavailableInMobile';
            } else if (this.isCompInClientSpecMap()) {
                overlay = 'preloader';
            } else {
                overlay = 'notInClientSpecMap';
            }
            if (!this.state.isAlive && (!this.state.overlay || this.state.overlay !== 'unresponsive')) {
                this.setState({
                    overlay
                });
            }
        },
        resize(nextProps) {
            const style = {};
            if (this.state && this.state.height &&
                (nextProps.style.height === this.state.height || nextProps.style.height !== this.props.style.height)) {
                style.height = undefined;
            }

            if (this.state && nextProps.style.width === this.state.width && this.state.width) {
                style.width = undefined;
            }

            if (!_.isEmpty(style)) {
                this.setState(style);
            }
        },

        isInMobileDevMode() {
            const isUnderMobileView = this.isUnderMobileView();
            if (isUnderMobileView) {
                const editorUrl = coreUtils.urlUtils.parseUrl(this.getEditorUrl());
                const appDefIdQueryParams = editorUrl.query.appDefinitionId;
                const appData = this.getAppData();

                return isUnderMobileView && appDefIdQueryParams && _.includes(appDefIdQueryParams, appData.appDefinitionId);
            }

            return false;
        },

        getEditorUrl() {
            return window.parent.location.href;
        }
    };

    _.forEach(compTypes, function (compType) {
        previewExtensionsRegistrar.registerCompExtension(compType, extension);
    });
});
