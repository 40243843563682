define(['lodash', 'coreUtils', 'previewExtensionsCore', 'santa-components'],
    function (_, coreUtils, previewExtensionsCore, santaComponents) {
        'use strict';

        const compType = 'wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay';
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;

        const extension = {
            propTypes: {
                compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
                languageCode: santaComponents.santaTypesDefinitions.RendererModel.languageCode,
                santaBase: santaComponents.santaTypesDefinitions.santaBase.isRequired,
                getClientSpecMapEntry: santaComponents.santaTypesDefinitions.getClientSpecMapEntry.isRequired,
                serviceTopology: santaComponents.santaTypesDefinitions.ServiceTopology.serviceTopology,
                isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen.isRequired
            },
            transformRefData: function transformRefData(refData) {
                if (this.props.compData.overlay !== 'unresponsive' &&
                    this.props.compData.overlay !== 'notInClientSpecMap') {
                    return;
                }

                const BORDER_SIZE = 2;
                const outerWidth = this.props.compData.style.width - 2 * BORDER_SIZE; // eslint-disable-line no-mixed-operators
                const widthIncludingPadding = Math.max(0, outerWidth);
                const hPadding = Math.floor(widthIncludingPadding * 0.1);
                const iconWidth = 45;
                const contentWidth = widthIncludingPadding - 2 * hPadding; // eslint-disable-line no-mixed-operators
                let textContainerWidth = '100%';

                delete refData.reloadButton;

                const refDataChanges = {
                    '': {
                        style: {
                            display: 'table'
                        }
                    },
                    content: {
                        style: {
                            display: 'table-cell', //block
                            verticalAlign: 'middle',
                            paddingLeft: '24px',
                            paddingRight: '24px',
                            width: contentWidth
                        }
                    },
                    textTitle: {
                        children: getPreviewTextTitle.call(this)
                    },
                    text: {
                        children: getPreviewText.call(this, refData)
                    },
                    img: {
                        style: {
                            marginLeft: '0px !important'
                        }
                    },
                    unavailableIcon: {
                        src: coreUtils.media.getMediaUrl(this.props.serviceTopology, 'tpaPreloader/alert-icon.png')
                    }
                };

                let refDataChangesForIcon = getDataChangesForIconOnTop();

                if (this.props.compData.style.width > 175) {
                    textContainerWidth = contentWidth - iconWidth;
                    refDataChangesForIcon = getDataChangesIconOnLeft(this.props, textContainerWidth);
                }

                _.merge(refDataChanges, refDataChangesForIcon);
                _.merge(refData, refDataChanges); // eslint-disable-line santa/no-side-effects
            }
        };

        const getLinkReactElement = function (applicationId, openHelp) { //eslint-disable-line react/display-name
            const appDefinitionId = getAppDefinitionId.call(this, applicationId);
            const appDefinitionName = getAppDefinitionName.call(this, applicationId);
            return santaComponents.utils.createReactElement('a', {
                'href': '#',
                onClick: function (appDefId, openHelpMethod, event) {
                    event.stopPropagation();
                    openHelpMethod(appDefId);
                }.bind(null, appDefinitionId, openHelp)
            }, appDefinitionName);
        };

        const getAppDefinitionId = function (applicationId) {
            const appData = this.props.getClientSpecMapEntry(applicationId);
            return _.get(appData, 'appDefinitionId');
        };

        const getAppDefinitionName = function (applicationId) {
            const appData = this.props.getClientSpecMapEntry(applicationId);
            return _.get(appData, 'appDefinitionName');
        };

        const getPreviewTextTitle = function () {
            const lang = this.props.languageCode;
            if (this.props.compData.overlay === 'unresponsive') {
                return getTranslatedText(lang, 'tpa_oops');
            }
            return getTranslatedText(lang, 'tpa_messages_app_installation_failed_message_title');
        };

        const getPreviewText = function (refData) {
            const lang = this.props.languageCode;

            if (this.props.compData.overlay === 'unresponsive') {
                return getPreviewUnresponsiveText.call(this, lang, refData);
            }
            return getPreviewUninstalledText.call(this, lang);
        };

        const getPreviewUnresponsiveText = function (lang, refData) {
            const appName = getAppDefinitionName.call(this, this.props.compData.applicationId);
            const unResponsiveTextTemplate = _.template(getTranslatedText(lang, 'tpa_unavail_problems'));
            const htmlText = ` ${unResponsiveTextTemplate({appName})} `;

            return [
                santaComponents.utils.createReactElement('span', {
                    'dangerouslySetInnerHTML': {
                        __html: htmlText
                    }
                }),
                getLinkReactElement.call(this, this.props.compData.applicationId, refData.openHelp),
                santaComponents.utils.createReactElement('span', {}, ` ${getTranslatedText(lang, 'tpa_unavail_problems_2')}`)
            ];
        };

        const getPreviewUninstalledText = function (lang) {
            return [
                santaComponents.utils.createReactElement('span', {
                    'dangerouslySetInnerHTML': {
                        __html: getTranslatedText(lang, 'tpa_messages_app_installation_failed_message_text')
                    }
                })
            ];
        };

        const getDataChangesIconOnLeft = function () {
            return {
                textContainer: {
                    style: {
                        verticalAlign: 'baseline',
                        display: 'block',
                        boxSizing: 'border-box',
                        float: 'left',
                        textAlign: 'left',
                        color: '#7a92a5',
                        fontWeight: '300',
                        fontSize: '13px'
                    }
                },
                textTitle: {
                    style: {
                        display: 'block',
                        color: '#2b5672',
                        fontSize: '14px',
                        fontWeight: '300',
                        lineHeight: '14px',
                        float: 'left'
                    }
                },
                iconContainer: {
                    style: {
                        width: 45,
                        boxSizing: 'border-box',
                        display: 'block',
                        marginBottom: 14,
                        marginTop: 14
                    }
                }
            };
        };

        const getDataChangesForIconOnTop = function () {
            return {
                textContainer: {
                    style: {
                        textAlign: 'center',
                        display: 'block'
                    }
                },
                iconContainer: {
                    style: {
                        display: 'block',
                        textAlign: 'center'
                    }
                }
            };
        };

        const getTranslatedText = function (lang, key) {
            return coreUtils.translationsLoader.getTranslation('tpaExtensionTranslations', lang, key);
        };

        previewExtensionsRegistrar.registerCompExtension(compType, extension);
    });
