define([
    'lodash',
    'santa-components',
    'previewExtensionsCore'
], function (_, santaComponents, previewExtensionsCore) {
    'use strict';

    const compType = 'wysiwyg.viewer.components.tpapps.TPAPopup';
    const previewExtensionsRegistrar = previewExtensionsCore.registrar;

    
    const extension = {
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
        },
        /* eslint-disable complexity */
        transformRefData: function transformRefData(refData) {
            const width = this.getPopupWidth();
            const height = this.getPopupHeight();
            if (width === '0%' || height === '0%' ||
                width === '0' || height === '0' ||
                width === 0 || height === 0 ||
                !this.props.isMobileView) {
                return;
            }
            const refDataChanges = {
                '': {
                    style: {
                        position: 'fixed',
                        width: 319,
                        height: 512,
                        marginLeft: 0,
                        marginTop: 0,
                        boxShadow: 'none',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        top: 0
                    }
                }
            };
            _.merge(refData, refDataChanges); // eslint-disable-line santa/no-side-effects
        }
    };

    previewExtensionsRegistrar.registerCompExtension(compType, extension);
});
