define([
    'lodash',
    'prop-types',
    'santa-components'
], function (_, PropTypes, santaComponents) {
    'use strict';
    const {utils: santaUtils} = santaComponents;

    const visibilityStates = ['VISIBLE', 'HIDDEN', 'NONE'];

    const NAMESPACE = 'PreviewVisibility';
    
    const definitions = santaUtils.createSantaTypesDefinitions({
        visibilityState: PropTypes.oneOf(visibilityStates),
        visibilityClasses: PropTypes.string
    }, NAMESPACE);
    
    
    return {
        definitions
    };
});
