define(['lodash', 'santa-components', 'previewExtensionsCore'], function (_, santaComponents, previewExtensionsCore) {
    'use strict';

    const extension = {
        propTypes: {
            shouldResetTinyMenuZIndex: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetTinyMenuZIndex
        },
        transformRefData: function transformRefData(refData) {
            if (this.props.shouldResetTinyMenuZIndex) {
                refData[''] = refData[''] || {};
                refData[''].style = _.assign({}, refData[''].style || {}, {
                    zIndex: 'auto'
                });
            }
        }
    };

    previewExtensionsCore.registrar.registerCompExtension('wysiwyg.viewer.components.MenuToggle', extension);
    previewExtensionsCore.registrar.registerCompExtension('wysiwyg.viewer.components.InlinePopupToggle', extension);
});
