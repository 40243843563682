define(['coreUtils', 'previewExtensionsCore', 'componentsPreviewExtensions/previewExtensions/helpers/previewModifications', 'santa-components'],
    function (coreUtils, previewExtensionsCore, previewModifications, santaComponents) {
        'use strict';

        const compType = 'wysiwyg.viewer.components.WFacebookLike';
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;

        function showComponentNonInteractiveTooltip(evt) {
            this.props.previewTooltipCallback(evt.target.getBoundingClientRect(), 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE');
        }

        function setWixLikeUrl(refData) {
            const fbLikeUrl = coreUtils.urlUtils.parseUrl(refData.iframe['data-src']);
            delete fbLikeUrl.search;
            fbLikeUrl.query.href = 'http://www.wix.com/create/website';

            refData.iframe['data-src'] = coreUtils.urlUtils.buildFullUrl(fbLikeUrl);
        }

        const extension = {
            propTypes: {
                previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback.isRequired,
                isSocialInteractionAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isSocialInteractionAllowed
            },

            transformRefData: function transformRefData(refData) {
                if (this.props.isSocialInteractionAllowed) {
                    return;
                }

                const onClick = showComponentNonInteractiveTooltip.bind(this);
                previewModifications.createBlockLayer(refData, onClick);
                setWixLikeUrl(refData);
                refData[''].onClick = onClick;
            }
        };

        previewExtensionsRegistrar.registerCompExtension(compType, extension);

        return extension;
    });
