define([
    'lodash',
    'coreUtils',
    'santa-components',
    'previewExtensionsCore'
],
function (_, coreUtils, santaComponents, previewExtensionsCore) {
    'use strict';

    const compType = 'wysiwyg.viewer.components.tpapps.TPAPreloaderOverlay';
    const previewExtensionsRegistrar = previewExtensionsCore.registrar;

    const extension = {
        propTypes: {
            clientSpecMap: santaComponents.santaTypesDefinitions.RendererModel.clientSpecMap,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen.isRequired,
            languageCode: santaComponents.santaTypesDefinitions.RendererModel.languageCode
        },
        transformRefData: function transformRefData(refData) {
            const clientSpecMap = this.props.clientSpecMap;
            const applicationId = this.props.applicationId;
            const name = clientSpecMap && clientSpecMap[applicationId] ? clientSpecMap[applicationId].appDefinitionName : '';
            const lang = this.props.languageCode;

            const lineHeight = this.props.compData.style.height;
            const refDataChanges = {
                content: {
                    style: {
                        textAlign: 'center',
                        fontSize: '13px'
                    }
                },
                preloader: {
                    style: {
                        marginTop: '-45px'
                    }
                },
                loadingText: {
                    style: {
                        lineHeight: `${lineHeight}px`
                    },
                    children: `${getTranslatedText(lang, 'TPA_PRELOADER_LOADING')} ${name} ...`
                }
            };
            _.merge(refData, refDataChanges); // eslint-disable-line santa/no-side-effects
        }
    };

    function getTranslatedText(lang, key) {
        return coreUtils.translationsLoader.getTranslation('tpaExtensionTranslations', lang, key);
    }

    previewExtensionsRegistrar.registerCompExtension(compType, extension);
});
