define(['previewExtensionsCore'], function (previewExtensionsCore) {
    'use strict';

    const compType = 'wysiwyg.viewer.components.MatrixGallery';
    const previewExtensionsRegistrar = previewExtensionsCore.registrar;
    const extension = {
        resetGalleryState(nextProps) {
            const initialState = this.getInitStateAndResetInstance(nextProps);
            this.registerReLayout();
            this.setState(initialState);
        }
    };

    previewExtensionsRegistrar.registerCompExtension(compType, extension);
});
