define(['santa-components'], function (santaComponents) {
    'use strict';

    return {
        /**
         * @param {Object} refData
         * @param {function} [onClick]
         */
        createBlockLayer: function createBlockLayer(refData, onClick) {
            const blockLayerProps = {
                style: {
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer'
                },
                className: 'blockLayer'
            };

            if (onClick) {
                blockLayerProps.onClick = onClick;
            }
            const blockLayer = santaComponents.utils.createReactElement('div', blockLayerProps);

            refData[''] = refData[''] || {}; // eslint-disable-line santa/no-side-effects
            refData[''].addChildren = refData[''].addChildren || [];
            refData[''].addChildren.push(blockLayer);
        }
    };
});
