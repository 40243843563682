define(['lodash', 'santa-components', 'previewExtensionsCore'],
    function (_, santaComponents, previewExtensionsCore) {
        'use strict';

        const compsType = ['wysiwyg.common.components.subscribeform.viewer.SubscribeForm'];
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;


        const extension = {
            propTypes: {
                isExternalNavigationAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isExternalNavigationAllowed,
                previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback.isRequired
            },

            blockSubmit(domNode) {
                if (this.props.isExternalNavigationAllowed) {
                    return;
                }
                this.props.previewTooltipCallback(domNode.getBoundingClientRect(), 'PREVIEW_TOOLTIP_SUBSCRIBE');
            },
            shouldBlockSubmit() {
                return !this.props.isExternalNavigationAllowed;
            }
        };
        _.forEach(compsType, function (compType) {
            previewExtensionsRegistrar.registerCompExtension(compType, extension);
        });
    });
