define(['lodash', 'santa-components', 'previewExtensionsCore'],

    function (_, santaComponents, previewExtensionsCore) {
        'use strict';

        function transformRefDataPosition(refData, skinPartName) {
            refData[skinPartName].style = _.assign({}, refData[skinPartName].style, {
                position: 'absolute'
            });
        }

        const compType = 'wysiwyg.viewer.components.MobileMediaZoom';
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;

        const extension = {
            propTypes: {
                renderFixedPositionContainers: santaComponents.santaTypesDefinitions.RenderFlags.renderFixedPositionContainers.isRequired,
                blockLayerWidth: santaComponents.santaTypesDefinitions.siteWidth.isRequired
            },
            transformRefData: function transformRefData(refData) {
                if (!this.props.renderFixedPositionContainers) {
                    return;
                }
                const blockLayerWidth = this.props.blockLayerWidth;

                refData.blockingLayer = refData.blockingLayer || {}; // eslint-disable-line santa/no-side-effects
                refData.blockingLayer.style = _.assign({}, refData.blockingLayer.style, {
                    width: blockLayerWidth,
                    height: '100vh',
                    marginLeft: `calc(50% - ${blockLayerWidth / 2}px)`
                });

                transformRefDataPosition(refData, 'xButton');
                transformRefDataPosition(refData, 'buttonPrev');
                transformRefDataPosition(refData, 'buttonNext');
            }
        };

        previewExtensionsRegistrar.registerCompExtension(compType, extension);
    });
