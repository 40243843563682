define(['previewExtensionsCore'], function (previewExtensionsCore) {
    'use strict';

    const extension = {
        getDefaultProps() {
            return {
                ignoreActivityReport: true
            };
        }
    };

    previewExtensionsCore.registrar.registerCompExtension('wysiwyg.viewer.components.ContactForm', extension);
    previewExtensionsCore.registrar.registerCompExtension('wysiwyg.viewer.components.DynamicContactForm', extension);
    previewExtensionsCore.registrar.registerCompExtension('wysiwyg.common.components.subscribeform.viewer.SubscribeForm', extension);
});
