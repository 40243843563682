define(['lodash', 'santa-components', 'previewExtensionsCore'],
    function (_, santaComponents, previewExtensionsCore) {
        'use strict';

        const compType = 'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton';
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;

        const extension = {
            propTypes: {
                isBackToTopButtonAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isBackToTopButtonAllowed
            },
            transformRefData: function transformRefData(refData) {
                if (this.props.isBackToTopButtonAllowed) {
                    return;
                }

                const refDataChanges = {
                    '': {
                        style: {
                            display: 'none'
                        }
                    }
                };

                _.merge(refData, refDataChanges); // eslint-disable-line santa/no-side-effects
            }
        };

        previewExtensionsRegistrar.registerCompExtension(compType, extension);

        return extension;
    });
