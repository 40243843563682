define([
    'previewExtensionsCore',
    'coreUtils',

    // Side-Effect
    'disqusComments'
], function (
    previewExtensionsCore,
    coreUtils
) {
    'use strict';

    const hashUtils = coreUtils.hashUtils;

    const extension = {
        getDisqusId() {
            return hashUtils.SHA256.hex_sha256('editor');
        },

        transformRefData: function transformRefData(refData) {
            refData.disqusCommentsPreviewOverlay = refData.disqusCommentsPreviewOverlay || {}; // eslint-disable-line santa/no-side-effects
            refData.disqusCommentsPreviewOverlay.style = refData.disqusCommentsPreviewOverlay.style || {};
            refData.disqusCommentsPreviewOverlay.style.display = '';
        }
    };

    previewExtensionsCore.registrar.registerCompExtension('wysiwyg.common.components.disquscomments.viewer.DisqusComments', extension);
});
