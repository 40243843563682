define(['santa-components', 'lodash', 'previewExtensionsCore'], function (santaComponents, _, previewExtensionsCore) {
    'use strict';

    const extension = {
        propTypes: {
            shouldBlockGoogleMapInteraction: santaComponents.santaTypesDefinitions.RenderFlags.shouldBlockGoogleMapInteraction
        },
        transformRefData(refData) {
            if (this.props.shouldBlockGoogleMapInteraction) {
                const blockLayerProps = {
                    style: {
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer'
                    },
                    className: 'blockLayer'
                };

                blockLayerProps.onClick = _.noop;
                const blockLayer = santaComponents.utils.createReactElement('div', blockLayerProps);

                refData[''] = refData[''] || {};
                refData[''].addChildren = refData[''].addChildren || [];
                refData[''].addChildren.push(blockLayer);
            }
        }
    };

    previewExtensionsCore.registrar.registerCompExtension('wysiwyg.viewer.components.GoogleMap', extension);
});
