define([
    'lodash',
    'react',
    'componentsPreviewExtensions/commonPreviewExtensions/visibilitySantaTypesDefinitions',
    'santa-components'
], function (
    _,
    React,
    visibilitySantaTypesDefinitions,
    santaComponents
) {
    'use strict';

    const MAX_Z_INDEX = Math.pow(2, 31) - 1;
    
    function setVisibility(style, visibilityState) {
        switch (visibilityState) {
            case 'VISIBLE':
                return _.omit(style, ['visibility']);
            case 'HIDDEN':
                return _.defaults({visibility: 'hidden'}, style);
            default:
                return style;
        }
    }

    function setZIndex(style, shouldShowComponentOnTop) {
        if (shouldShowComponentOnTop) {
            return _.defaults({zIndex: MAX_Z_INDEX}, style);
        }

        return style;
    }

    function setCompOpacity(style, opacity) {
        if (opacity) {
            return _.defaults({opacity}, style);
        }

        return style;
    }

    function transformStyle(style, visibilityState, shouldShowComponentOnTop, componentOpacity) {
        return setCompOpacity(setZIndex(setVisibility(style, visibilityState), shouldShowComponentOnTop), componentOpacity);
    }

    function transformClassName(className, visibilityClasses) {
        if (!visibilityClasses) {
            return className;
        }

        return _(className)
            .split(' ')
            .union(visibilityClasses.split(' '))
            .join(' ');
    }

    return baseClass => {
        class BaseComponentPreviewExtension extends baseClass {
            render() {
                const reactElement = super.render();
                return React.cloneElement(reactElement, {
                    style: transformStyle(reactElement.props.style,
                        this.props.visibilityState,
                        this.props.shouldShowComponentOnTop,
                        this.props.componentOpacity),
                    className: transformClassName(reactElement.props.className, this.props.visibilityClasses)
                });
            }
        }

        BaseComponentPreviewExtension.displayName = `BasePreviewExtension(${baseClass.displayName})`;

        BaseComponentPreviewExtension.propTypes = _.defaults({
            visibilityState: visibilitySantaTypesDefinitions.definitions.visibilityState,
            shouldShowComponentOnTop: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.shouldShowComponentOnTop,
            componentOpacity: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.componentOpacity,
            visibilityClasses: visibilitySantaTypesDefinitions.definitions.visibilityClasses
        }, baseClass.propTypes);

        return BaseComponentPreviewExtension;
    };
});

