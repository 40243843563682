define([
    'lodash',
    'santa-components',
    'skins',
    'previewExtensionsCore',
    'compUtils',
    'componentsPreviewExtensions/commonPreviewExtensions/baseComponentPreviewExtension',

    /** components preview extensions */
    'componentsPreviewExtensions/previewExtensions/datePickerPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/backToTopButtonPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/facebookLikePreviewExtension',
    'componentsPreviewExtensions/previewExtensions/facebookSharePreviewExtension',
    'componentsPreviewExtensions/previewExtensions/socialComponentsPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/slideShowGalleryPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/tpaUnavailableMessageOverlayPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/tpaPreloaderOverlayExtension',
    'componentsPreviewExtensions/previewExtensions/tpaModalExtension',
    'componentsPreviewExtensions/previewExtensions/tpaPopupExtension',
    'componentsPreviewExtensions/previewExtensions/tpaCompsExtension',
    'componentsPreviewExtensions/previewExtensions/fixedPositionContainerPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/mobileMediaZoomPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/blockSiteMembersPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/matrixGalleryPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/subscribeFormPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/formPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/comboBoxPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/controllerPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/quickActionBarPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/disqusCommentsPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/gridPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/memberLoginPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/googleMapPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/subscribeFormRemoveMaxWidthForAddPanel',
    'componentsPreviewExtensions/previewExtensions/tinyMenuAddPanelPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/inlinePopupToggleAddPanelPreviewExtension',
    'componentsPreviewExtensions/previewExtensions/mediaPlayerPreviewExtension'
],
function (
    _,
    santaComponents,
    skins,
    previewExtensionsCore,
    compUtils,
    baseComponentPreviewExtension
) {
    'use strict';

    _(santaComponents.components)
        .filter('getComponentSkins')
        .forEach(comp => skins.skinsMap.addBatch(comp.getComponentSkins()));


    /** @exports componentsPreviewExtensions */
    return {
        extendCompClasses() {
            previewExtensionsCore.registrar.extendCompClasses();
            compUtils.compFactory.registerCommonExtensionForAllComponents(baseComponentPreviewExtension);
            previewExtensionsCore.registrar.registerPreviewClasses();
            return true;
        },
        baseComponentPreviewExtension
        
    };
});
