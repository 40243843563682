define([
    'santa-components',
    'previewExtensionsCore',
    'componentsPreviewExtensions/previewExtensions/helpers/previewModifications',
    'compUtils',
    'facebookShare'
], function (
    santaComponents,
    previewExtensionsCore,
    previewModifications,
    compUtils,
    facebookShare
) {
    'use strict';

    const compType = 'wysiwyg.viewer.components.FacebookShare';
    compUtils.compFactory.register(compType, facebookShare);

    const previewExtensionsRegistrar = previewExtensionsCore.registrar;

    function showComponentNonInteractiveTooltip(evt) {
        this.props.previewTooltipCallback(evt.target.getBoundingClientRect(), 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE');
    }

    const extension = {
        propTypes: {
            previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback.isRequired,
            isSocialInteractionAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isSocialInteractionAllowed
        },
        transformRefData: function transformRefData(refData) {
            if (this.props.isSocialInteractionAllowed) {
                return;
            }

            const onClick = showComponentNonInteractiveTooltip.bind(this);
            previewModifications.createBlockLayer(refData, onClick);

            // make sure the parent container's width is adjusted to its content
            refData[''] = refData[''] || {}; // eslint-disable-line santa/no-side-effects
            refData[''].style = refData[''].style || {};
            refData[''].style.width = 'auto';
            refData[''].onClick = onClick;
        }
    };

    previewExtensionsRegistrar.registerCompExtension(compType, extension);
});
