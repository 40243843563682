define(['lodash', 'santa-components', 'previewExtensionsCore'], function (_, santaComponents, previewExtensionsCore) {
    'use strict';

    const actionBarExtension = {
        propTypes: {
            previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback
        },
        createActionClickHandlerInPreview(e) {
            if (_.isFunction(this.props.previewTooltipCallback)) {
                this.props.previewTooltipCallback(e.target.getBoundingClientRect(), 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE');
            }
        }
    };

    previewExtensionsCore.registrar.registerCompExtension('wysiwyg.viewer.components.QuickActionBar', actionBarExtension);
});
