define(['lodash', 'previewExtensionsCore', 'santa-components', 'compUtils', 'inlinePopup'],

    function (_, previewExtensionsCore, santaComponents, compUtils, inlinePopup) {
        'use strict';

        const tinyMenuCompType = 'wysiwyg.viewer.components.mobile.TinyMenu';
        const menuContainerToggleCompType = 'wysiwyg.viewer.components.MenuToggle';
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;
        compUtils.compFactory.register(menuContainerToggleCompType, inlinePopup.MenuToggle);

        const extension = {
            propTypes: {
                renderFixedPositionContainers: santaComponents.santaTypesDefinitions.RenderFlags.renderFixedPositionContainers.isRequired,
                isMeshLayoutMechanism: santaComponents.santaTypesDefinitions.Layout.isMeshLayoutMechanism.isRequired
            },

            getTransformedCssStates() {
                if (this.props.renderFixedPositionContainers) {
                    return this.state;
                }
                return _.omit(this.state, '$fixed'); //return transformed state
            },

            getRootPosition(style) {
                if (this.props.renderFixedPositionContainers) {
                    return style.position;
                }

                return this.props.isMeshLayoutMechanism ? '' : 'absolute';
            }
        };

        previewExtensionsRegistrar.registerCompExtension(tinyMenuCompType, extension);
        previewExtensionsRegistrar.registerCompExtension(menuContainerToggleCompType, extension);
    });
