define(['lodash', 'previewExtensionsCore', 'santa-components'],
    function (_, previewExtensionsCore, santaComponents) {
        'use strict';

        const compTypes = ['wysiwyg.viewer.components.LoginSocialBar', 'wysiwyg.viewer.components.mobile.TinyMenuMemberSection'];
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;

        const extension = {
            propTypes: {
                previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback.isRequired
            },
            getLogoutFunction(event) {
                return _.isFunction(this.props.previewTooltipCallback) ?
                    this.props.previewTooltipCallback.bind(this, event.target.getBoundingClientRect(), 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE') : _.noop;
            },
            getLoginFunction(event) {
                return _.isFunction(this.props.previewTooltipCallback) ?
                    this.props.previewTooltipCallback.bind(this, event.target.getBoundingClientRect(), 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE') : _.noop;
            }
        };

        _.forEach(compTypes, function (compType) {
            previewExtensionsRegistrar.registerCompExtension(compType, extension);
        });
    });
