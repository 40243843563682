define(['santa-components', 'lodash', 'previewExtensionsCore'],
    function (santaComponents, _, previewExtensionsCore) {
        'use strict';

        const compsType = ['wysiwyg.viewer.components.dialogs.EnterPasswordDialog', 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.SignUpDialog', 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.MemberLoginDialog',
            'wysiwyg.viewer.components.dialogs.siteMemberDialogs.RequestPasswordResetDialog', 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.ResetPasswordDialog', 'wysiwyg.viewer.components.dialogs.NotificationDialog'];
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;

        function overrideOnClickWithTooltip(refData, refKey) {
            if (refData[refKey]) {
                refData[refKey].onClick = function () {
                    const domNode = this.refs[refKey].getBoundingClientRect();
                    this.props.previewTooltipCallback(domNode, 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE');
                }.bind(this);
            }
        }

        function createDummyLoginButton(type, backgroundColor) {
            return santaComponents.utils.createReactElement('button', {
                style: {
                    display: 'block',
                    padding: '0',
                    height: '48px',
                    lineHeight: '48px',
                    width: '100%',
                    fontFamily: 'Helvetica',
                    fontSize: '14px',
                    color: 'white',
                    marginBottom: '24px',
                    fontWeight: '300',
                    cursor: 'pointer',
                    outline: '0',
                    backgroundColor
                },
                'data-aid': `dummy-login-button-${type}`,
                onClick: function (event) {
                    const domNode = event.target.getBoundingClientRect();
                    this.props.previewTooltipCallback(domNode, 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE');
                }.bind(this),
                children: [this.getText(this.props.language, this._getSocialTranslationKeys()[type])]
            });
        }

        const extension = {
            propTypes: {
                isExternalNavigationAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isExternalNavigationAllowed,
                previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback.isRequired,
                dialogToDisplay: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.dialogToDisplay
            },
            blockSubmit(domNode) {
                if (this.props.isExternalNavigationAllowed) {
                    return;
                }
                this.props.previewTooltipCallback(domNode.getBoundingClientRect(), 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE');
            },
            /**
             * @returns {boolean}
             */
            isPasswordProtectedDialog() {
                return this.props.dialogToDisplay === 'enterPassword';
            },
            shouldBlockSubmit() {
                // The extension was added to prevent loading another editor in preview's iframe on successful webmaster
                // login (see SE-3992).
                //
                // That prevents navigating to a password-protected page, too (see SE-3256).
                //
                // Ignore the render flag isExternalNavigationAllowed for a password-protected dialog. Navigation to
                // a password-protected page isn't an external navigation, right?
                return !this.props.isExternalNavigationAllowed && !this.isPasswordProtectedDialog();
            },
            transformRefData: function transformRefData(refData) {
                if (this.props.isExternalNavigationAllowed) {
                    return;
                }

                refData[''] = refData[''] || {}; // eslint-disable-line santa/no-side-effects

                refData[''].style = _.assign({}, refData[''].style, {
                    overflow: 'hidden'
                });

                refData.blockingLayer.style = _.assign({}, refData.blockingLayer.style, {
                    position: 'absolute'
                });

                overrideOnClickWithTooltip.call(this, refData, 'okButton');
                if (this.isSocialLogin()) {
                    const children = [];
                    if (this.props.smSettings.socialLoginFacebookEnabled) {
                        children.push(createDummyLoginButton.call(this, 'facebook', '#3b5998'));
                    }
                    if (this.props.smSettings.socialLoginGoogleEnabled) {
                        children.push(createDummyLoginButton.call(this, 'google', '#e53935'));
                    }
                    const dummyFrame = santaComponents.utils.createReactElement('div', {
                        style: {
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%'
                        },
                        children
                    });
                    refData.socialLoginWrapper.addChildren = refData.socialLoginWrapper.addChildren || [];
                    refData.socialLoginWrapper.addChildren.push(dummyFrame);
                    refData.socialLoginIframe = null;
                    refData.socialLoginLoader = null;
                }
            }
        };
        _.forEach(compsType, function (compType) {
            previewExtensionsRegistrar.registerCompExtension(compType, extension);
        });
    });
