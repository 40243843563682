define(['santa-components', 'lodash', 'previewExtensionsCore', 'coreUtils', 'compUtils', 'slideShowGallery'], function (santaComponents, _, previewExtensionsCore, coreUtils, compUtils, slideShowGallery) {
    'use strict';

    const compType = 'wysiwyg.viewer.components.SlideShowGallery';
    compUtils.compFactory.register(compType, slideShowGallery);

    const previewExtensionsRegistrar = previewExtensionsCore.registrar;

    function addSlideShowIcon(refData, serviceTopology) {
        const iconUrl = coreUtils.media.getMediaUrl(serviceTopology, 'slideshowGallery/ico_slide.png');

        const overlay = santaComponents.utils.createReactElement('div', {
            style: {
                position: 'absolute',
                top: '50%',
                left: '0',
                width: '100%',
                marginTop: '-18px',
                textAlign: 'center'
            },
            className: 'slideShowClickBlocker',
            children: [santaComponents.utils.createReactElement('b', {
                className: 'slideShowClickBlocker',
                style: {
                    display: 'inline-block',
                    borderRadius: '3px',
                    background: `#222 url(${iconUrl}) no-repeat 8px 50%`,
                    opacity: '0.6',
                    color: '#ffffff',
                    padding: '0 14px 0 66px',
                    whiteSpace: 'nowrap',
                    height: '38px',
                    lineHeight: '39px',
                    fontSize: '12px',
                    position: 'static',
                    width: 'auto'
                },
                children: ['Slide Deck Gallery']
            })]
        });

        refData[''].addChildren = refData[''].addChildren || [];
        refData[''].addChildren.push(overlay);
    }

    const extension = {
        propTypes: {
            santaBase: santaComponents.santaTypesDefinitions.santaBase.isRequired,
            isSlideShowGalleryClickAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isSlideShowGalleryClickAllowed,
            shouldResetSlideShowNextPrevButtonsPosition: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetSlideShowNextPrevButtonsPosition,
            serviceTopology: santaComponents.santaTypesDefinitions.ServiceTopology.serviceTopology
        },

        getButtonsState() {
            return {
                $editMode: this.props.isSlideShowGalleryClickAllowed ? '' : 'showButtons'
            };
        },
        transformRefData: function transformRefData(refData) {
            if (!this.props.isSlideShowGalleryClickAllowed) {
                refData[''] = refData[''] || {};
                addSlideShowIcon(refData, this.props.serviceTopology);
            }

            if (this.props.shouldResetSlideShowNextPrevButtonsPosition) {
                refData[''] = refData[''] || {};
                refData[''].style = _.assign({}, refData[''].style, {overflow: 'visible'});
                refData.buttonNext = refData.buttonNext || {};
                refData.buttonNext.style = _.assign({}, refData.buttonNext.style, {right: 0});

                refData.buttonPrev = refData.buttonPrev || {};
                refData.buttonPrev.style = _.assign({}, refData.buttonPrev.style, {left: 0});
            }
        },
        resetGalleryState(nextProps) {
            const initialState = this.getInitStateAndResetInstance(nextProps);
            this.registerReLayout();
            this.setState(initialState);
        }

    };

    previewExtensionsRegistrar.registerCompExtension('wysiwyg.viewer.components.SlideShowGallery', extension);

    return extension;
});
