define(['lodash', 'previewExtensionsCore', 'componentsPreviewExtensions/previewExtensions/helpers/previewModifications', 'santa-components'],
    function (_, previewExtensionsCore, previewModifications, santaComponents) {
        'use strict';

        const compTypes = [
            'wysiwyg.common.components.exitmobilemode.viewer.ExitMobileMode',
            'wysiwyg.viewer.components.WFacebookComment',
            'wysiwyg.viewer.components.ItunesButton',
            'wysiwyg.viewer.components.PayPalButton',
            'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt',
            'wysiwyg.viewer.components.VKShareButton',
            'wysiwyg.viewer.components.WGooglePlusOne',
            'wysiwyg.viewer.components.WTwitterFollow',
            'wysiwyg.viewer.components.WTwitterTweet',
            'wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton'
        ];
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;

        function showComponentNonInteractiveTooltip(evt) {
            this.props.previewTooltipCallback(evt.target.getBoundingClientRect(), 'PREVIEW_TOOLTIP_GOTO_LIVE_SITE');
        }

        function generateCompExtension() {
            return {
                propTypes: {
                    previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback.isRequired,
                    isSocialInteractionAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isSocialInteractionAllowed
                },
                transformRefData(refData) {
                    if (!this.props.isSocialInteractionAllowed) {
                        const onClick = showComponentNonInteractiveTooltip.bind(this);
                        previewModifications.createBlockLayer(refData, onClick);
                        refData[''].onClick = onClick;
                    }
                }
            };
        }

        _.forEach(compTypes, function (compType) {
            previewExtensionsRegistrar.registerCompExtension(compType, generateCompExtension());
        });

        return generateCompExtension;
    });
