define(['lodash', 'santa-components', 'previewExtensionsCore'], function (_, santaComponents, previewExtensionsCore) {
    'use strict';

    const extension = {
        propTypes: {
            shouldResetSubscribeFormMinMaxWidth: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetSubscribeFormMinMaxWidth
        },
        transformRefData(refData) {
            if (this.props.shouldResetSubscribeFormMinMaxWidth) {
                refData[''] = refData[''] || {};
                refData[''].style.minWidth = 'initial';
                refData[''].style.maxWidth = 'initial';
            }
        }
    };

    previewExtensionsCore.registrar.registerCompExtension('wysiwyg.common.components.subscribeform.viewer.SubscribeForm', extension);
});
