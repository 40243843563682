define([
    'lodash',
    'previewExtensionsCore'
],
function (_, previewExtensionsCore) {
    'use strict';

    const compType = 'wysiwyg.viewer.components.tpapps.TPAModal';
    const previewExtensionsRegistrar = previewExtensionsCore.registrar;

    const extension = {
        transformRefData: function transformRefData(refData) {
            if (this.state.$displayDevice === 'mobile') {
                const refDataChanges = {
                    frameWrap: {
                        style: {
                            overflowY: 'none',
                            width: 319,
                            height: 512
                        }
                    },
                    dialog: {
                        style: {
                            position: 'relative'
                        }
                    }
                };
                _.merge(refData, refDataChanges);
            }
        }
    };

    previewExtensionsRegistrar.registerCompExtension(compType, extension);
});
