define(['lodash', 'previewExtensionsCore'],
    function (_, previewExtensionsCore) {
        'use strict';

        const CALENDAR_PREVIEW_STATES = {
            FAKE_DATA: 'fakeData',
            OPEN: 'open'
        };
        const compType = 'wysiwyg.viewer.components.inputs.DatePicker';
        const previewExtensionsRegistrar = previewExtensionsCore.registrar;

        const extension = {
            getCalendarPreviewProperties: function getCalendarPreviewProperties() {
                return this.isPreviewState(CALENDAR_PREVIEW_STATES.FAKE_DATA) ? {
                    id: `${this.props.id}calendar`,
                    ref: `${this.props.id}calendar`,
                    style: {},
                    dateUtils: this.dateUtils,
                    calendarDate: new Date(2080, 8, 12),
                    today: new Date(2080, 8, 12),
                    closeCalendar: _.noop,
                    onDateChange: _.noop,
                    allowPastDates: false,
                    allowFutureDates: true,
                    selectedDate: new Date(2080, 8, 13),
                    translatedKeys: this.getTranslatedKeys(),
                    isRtlCalendar: this.isRtlCalendar(),
                    weekStartsOn: this.getWeekStartsOn()
                } : this.getCalendarProperties();
            },
            transformRefData: function transformRefData(refData) {
                const shouldRenderPreviewCalendar = this.isPreviewState(CALENDAR_PREVIEW_STATES.FAKE_DATA) ||
                    this.isPreviewState(CALENDAR_PREVIEW_STATES.OPEN);

                if (!this.props.isMobileView && shouldRenderPreviewCalendar) {
                    refData.calendar = this.createChildComponent(
                        {},
                        'wysiwyg.viewer.components.Calendar',
                        'calendar',
                        this.getCalendarPreviewProperties()
                    );
                }
            }
        };

        previewExtensionsRegistrar.registerCompExtension(compType, extension);
    });
