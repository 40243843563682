define([
    'prop-types',
    'lodash',
    'coreUtils',
    'santa-components',
    'previewExtensionsCore'],
function (PropTypes, _, coreUtils, santaComponents, previewExtensionsCore) {
    'use strict';

    const compType = 'platform.components.AppController';
    const previewExtensionSkin = 'platform.components.skins.controllerIconAndNameSkin';
    const previewExtensionsRegistrar = previewExtensionsCore.registrar;
    const SantaTypes = santaComponents.santaTypesDefinitions;

    const controllerStyleId = coreUtils.santaTypes.shortenStyleId(previewExtensionSkin);

    const extension = {
        propTypes: {
            showControllers: SantaTypes.RenderFlags.showControllers,
            applicativeUIData: SantaTypes.AppController.applicativeUIData,
            skin: coreUtils.staticValue(PropTypes.string, previewExtensionSkin),
            styleId: coreUtils.staticValue(PropTypes.string, controllerStyleId),
            isMobile: SantaTypes.isMobileView.isRequired,
            isVisible: SantaTypes.AppController.isVisible.isRequired,
            compData: SantaTypes.Component.compData.isRequired
        },

        transformRefData: function transformRefData(refData) {
            if (!this.props.showControllers) {
                refData.style = refData.style || {};
                refData.style.display = 'none';
            }
            refData.icon = refData.icon || {}; // eslint-disable-line santa/no-side-effects
            refData.icon.src = _.get(this.props.applicativeUIData, 'icon');
            refData.name = refData.name || {}; // eslint-disable-line santa/no-side-effects
            refData.name.children = this.props.compData.name;
        }
    };

    previewExtensionsRegistrar.registerCompExtension(compType, extension);
});
