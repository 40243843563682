define([
    'lodash',
    'previewExtensionsCore'
], function (
    _,
    previewExtensionsCore
) {
    'use strict';

    const compType = 'wysiwyg.viewer.components.Grid';
    const previewExtensionsRegistrar = previewExtensionsCore.registrar;

    const FieldType = Object.freeze({
        STRING: 'string',
        NUMBER: 'number',
        DATE: 'date',
        IMAGE: 'image',
        BOOLEAN: 'bool',
        RICH_TEXT: 'richText'
    });

    const DataSourceType = Object.freeze({
        STATIC: 'static',
        DYNAMIC: 'dynamic'
    });

    const ViewMode = Object.freeze({
        EDITOR: 'editor',
        PREVIEW: 'preview'
    });

    const defaultImage = '//static.parastorage.com/services/santa-resources/resources/viewer/grid/image-placeholder.png';

    function generateDummyData(column) {
        let dummyData;
        switch (column.type) {
            case FieldType.STRING:
                dummyData = `[${column.label} Data]`;
                break;
            case FieldType.NUMBER:
                dummyData = 123456;
                break;
            case FieldType.DATE:
                dummyData = new Date();
                break;
            case FieldType.IMAGE:
                dummyData = defaultImage;
                break;
            case FieldType.BOOLEAN:
                dummyData = Math.random() > 0.5;
                break;
            case FieldType.RICH_TEXT:
                dummyData = `<div>[${column.label} Data]</div>`;
                break;
        }
        return dummyData;
    }

    function isInEditorEditMode(props) {
        return props.componentViewMode === ViewMode.EDITOR;
    }

    function cloneProps(props) {
        return _.assign({}, props, {
            compData: _.cloneDeep(props.compData),
            compProp: _.cloneDeep(props.compProp)
        });
    }

    const extension = {
        transformPropsWithPreviewDummyData(nextProps, shouldCreateDataSource) {
            const clonedProps = cloneProps(nextProps);

            const columns = clonedProps.compProp.columns;
            const rows = clonedProps.compData.rows;
            const newRows = _.cloneDeep(rows);
            let rowsWereModified = false;

            _.forEach(columns, function (column) {
                _.forEach(newRows, function (row) {
                    if (!_.has(row, column.dataPath)) {
                        _.set(row, column.dataPath, generateDummyData(column));
                        rowsWereModified = true;
                    }
                });
            });

            if (rowsWereModified) {
                clonedProps.compData.rows = newRows;
                clonedProps.compData.revision = Date.now();
            }

            if (!_.isEqual(this.props.compProp.columns, clonedProps.compProp.columns) || shouldCreateDataSource) {
                clonedProps.compData.dataSource = {
                    type: DataSourceType.STATIC,
                    id: Date.now()
                };
            }

            return clonedProps;
        },

        transformPropsWithIncompleteData(nextProps, didViewModeChange) {
            // Generate dummy data only while editing, not in preview mode
            if (isInEditorEditMode(nextProps)) {
                return this.transformPropsWithPreviewDummyData(nextProps, didViewModeChange);
            }

            return cloneProps(nextProps);
        }
    };

    previewExtensionsRegistrar.registerCompExtension(compType, extension);
});
